export default { 
    head: {
        title: '',
        title2: '惠采购电子交易平台',
        citycode:'',//城市编号
        planName:'',
        dljgLoginUrl:'',
        tbrLoginUrl:'',
        zjLoginUrl:'',
        meta: {
            description: '交易平台,招投标,电子交易平台',
            keywords: '交易平台,招投标,电子交易平台',
        },
        icon: '/favicon.ico',
        logo:'',
    },
    plugins: {
        element: true,
        myComs: true,
        vuexStorage: false,
        jquery: true,
        sentry: false,
        cnzz: false
    },
    proxy: {
        
    },
}
