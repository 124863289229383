export const state = () => ({
    fileUrl: '/standalonfile',

    webUrl: `/web`
})

export const mutations = {
    
}

export const getters = {
    
}
