import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2124bb40 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _6ca32b72 = () => interopDefault(import('..\\pages\\business\\index.vue' /* webpackChunkName: "pages/business/index" */))
const _3624b37e = () => interopDefault(import('..\\pages\\cahandle\\index.vue' /* webpackChunkName: "pages/cahandle/index" */))
const _5a6c947f = () => interopDefault(import('..\\pages\\download\\index.vue' /* webpackChunkName: "pages/download/index" */))
const _feec0f1e = () => interopDefault(import('..\\pages\\guide\\index.vue' /* webpackChunkName: "pages/guide/index" */))
const _115da438 = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _6883c858 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _5a577f5b = () => interopDefault(import('..\\pages\\outside-cjwt\\index.vue' /* webpackChunkName: "pages/outside-cjwt/index" */))
const _7dabdf84 = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _0471ee2c = () => interopDefault(import('..\\pages\\business\\detail.vue' /* webpackChunkName: "pages/business/detail" */))
const _0b27c6c2 = () => interopDefault(import('..\\pages\\business\\detail-sd.vue' /* webpackChunkName: "pages/business/detail-sd" */))
const _78973d5e = () => interopDefault(import('..\\pages\\business\\index copy.vue' /* webpackChunkName: "pages/business/index copy" */))
const _368f2289 = () => interopDefault(import('..\\pages\\login\\index-zfcg.vue' /* webpackChunkName: "pages/login/index-zfcg" */))
const _44586e3f = () => interopDefault(import('..\\pages\\news\\detail.vue' /* webpackChunkName: "pages/news/detail" */))
const _009914d8 = () => interopDefault(import('..\\pages\\business\\components\\file-download.vue' /* webpackChunkName: "pages/business/components/file-download" */))
const _acec5f2c = () => interopDefault(import('..\\pages\\business\\components\\link-paper.vue' /* webpackChunkName: "pages/business/components/link-paper" */))
const _aa074342 = () => interopDefault(import('..\\pages\\business\\components\\search-bar.vue' /* webpackChunkName: "pages/business/components/search-bar" */))
const _3253cf74 = () => interopDefault(import('..\\pages\\business\\components\\search-bar copy.vue' /* webpackChunkName: "pages/business/components/search-bar copy" */))
const _9d0e4624 = () => interopDefault(import('..\\pages\\business\\components\\table-hxrgs.vue' /* webpackChunkName: "pages/business/components/table-hxrgs" */))
const _74567457 = () => interopDefault(import('..\\pages\\register\\components\\btn-yzm.vue' /* webpackChunkName: "pages/register/components/btn-yzm" */))
const _349aa4d6 = () => interopDefault(import('..\\pages\\register\\components\\captcha.vue' /* webpackChunkName: "pages/register/components/captcha" */))
const _739a4276 = () => interopDefault(import('..\\pages\\register\\components\\input-yzm.vue' /* webpackChunkName: "pages/register/components/input-yzm" */))
const _2d9b46b4 = () => interopDefault(import('..\\pages\\register\\components\\tos.vue' /* webpackChunkName: "pages/register/components/tos" */))
const _4a272472 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _3f332961 = () => interopDefault(import('..\\pages\\index\\sections\\banner.vue' /* webpackChunkName: "pages/index/sections/banner" */))
const _2db30cb5 = () => interopDefault(import('..\\pages\\index\\sections\\business.vue' /* webpackChunkName: "pages/index/sections/business" */))
const _4cf52f50 = () => interopDefault(import('..\\pages\\index\\sections\\enters.vue' /* webpackChunkName: "pages/index/sections/enters" */))
const _57c60e5d = () => interopDefault(import('..\\pages\\index\\sections\\footlink.vue' /* webpackChunkName: "pages/index/sections/footlink" */))
const _1591f95f = () => interopDefault(import('..\\pages\\index\\sections\\logins.vue' /* webpackChunkName: "pages/index/sections/logins" */))
const _55ddccc8 = () => interopDefault(import('..\\pages\\index\\sections\\news.vue' /* webpackChunkName: "pages/index/sections/news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2124bb40,
    alias: "/about/index.html",
    name: "about"
  }, {
    path: "/business",
    component: _6ca32b72,
    alias: "/business/index.html",
    name: "business"
  }, {
    path: "/cahandle",
    component: _3624b37e,
    alias: "/cahandle/index.html",
    name: "cahandle"
  }, {
    path: "/download",
    component: _5a6c947f,
    alias: "/download/index.html",
    name: "download"
  }, {
    path: "/guide",
    component: _feec0f1e,
    alias: "/guide/index.html",
    name: "guide"
  }, {
    path: "/login",
    component: _115da438,
    alias: "/login/index.html",
    name: "login"
  }, {
    path: "/news",
    component: _6883c858,
    alias: "/news/index.html",
    name: "news"
  }, {
    path: "/outside-cjwt",
    component: _5a577f5b,
    alias: "/outside-cjwt/index.html",
    name: "outside-cjwt"
  }, {
    path: "/register",
    component: _7dabdf84,
    alias: "/register/index.html",
    name: "register"
  }, {
    path: "/business/detail",
    component: _0471ee2c,
    alias: "/business/detail/index.html",
    name: "business-detail"
  }, {
    path: "/business/detail-sd",
    component: _0b27c6c2,
    alias: "/business/detail-sd/index.html",
    name: "business-detail-sd"
  }, {
    path: "/business/index%20copy",
    component: _78973d5e,
    alias: "/business/index%20copy/index.html",
    name: "business-index copy"
  }, {
    path: "/login/index-zfcg",
    component: _368f2289,
    alias: "/login/index-zfcg/index.html",
    name: "login-index-zfcg"
  }, {
    path: "/news/detail",
    component: _44586e3f,
    alias: "/news/detail/index.html",
    name: "news-detail"
  }, {
    path: "/business/components/file-download",
    component: _009914d8,
    alias: "/business/components/file-download/index.html",
    name: "business-components-file-download"
  }, {
    path: "/business/components/link-paper",
    component: _acec5f2c,
    alias: "/business/components/link-paper/index.html",
    name: "business-components-link-paper"
  }, {
    path: "/business/components/search-bar",
    component: _aa074342,
    alias: "/business/components/search-bar/index.html",
    name: "business-components-search-bar"
  }, {
    path: "/business/components/search-bar%20copy",
    component: _3253cf74,
    alias: "/business/components/search-bar%20copy/index.html",
    name: "business-components-search-bar copy"
  }, {
    path: "/business/components/table-hxrgs",
    component: _9d0e4624,
    alias: "/business/components/table-hxrgs/index.html",
    name: "business-components-table-hxrgs"
  }, {
    path: "/register/components/btn-yzm",
    component: _74567457,
    alias: "/register/components/btn-yzm/index.html",
    name: "register-components-btn-yzm"
  }, {
    path: "/register/components/captcha",
    component: _349aa4d6,
    alias: "/register/components/captcha/index.html",
    name: "register-components-captcha"
  }, {
    path: "/register/components/input-yzm",
    component: _739a4276,
    alias: "/register/components/input-yzm/index.html",
    name: "register-components-input-yzm"
  }, {
    path: "/register/components/tos",
    component: _2d9b46b4,
    alias: "/register/components/tos/index.html",
    name: "register-components-tos"
  }, {
    path: "/",
    component: _4a272472,
    alias: "/index.html",
    name: "index",
    children: [{
      path: "sections/banner",
      component: _3f332961,
      name: "index-sections-banner"
    }, {
      path: "sections/business",
      component: _2db30cb5,
      name: "index-sections-business"
    }, {
      path: "sections/enters",
      component: _4cf52f50,
      name: "index-sections-enters"
    }, {
      path: "sections/footlink",
      component: _57c60e5d,
      name: "index-sections-footlink"
    }, {
      path: "sections/logins",
      component: _1591f95f,
      name: "index-sections-logins"
    }, {
      path: "sections/news",
      component: _55ddccc8,
      name: "index-sections-news"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
